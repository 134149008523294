<template>
  <div v-if="membership">
    <profileParts />
    <h3 class="flix-html-h3">{{ $t('message.membership') }}</h3>
    <div class="flix-row">
      <div class="flix-col-md-6" :class="[{'flix-hidden-xs': membership.membership === 'premium'}]">
        <div class="flix-form-group">
          <freemium :membership="membership.membership" :class="[{'inactive': membership.membership === 'premium'}]" />
        </div>
      </div>
      <div class="flix-col-md-6">
        <div class="flix-form-group">
        <premium :membership="membership.membership"/>
        </div>
      </div>
      <div class="flix-col-md-12 flix-text-center flix-form-group">
        <a href="https://web.bookingflix.com/prices" target="_blank" class="flix-btn flix-btn-block flix-btn-default">{{ $tc('message.function', 2) }} &amp; {{ $tc('message.price', 2) }}</a>
      </div>
      <div class="flix-col-md-12">
        <div class="flix-form-group flix-pull-right flix-text-right">
          <router-link :to="{name: 'dashboardDeleteAccount'}" class="flix-html-a flix-text-danger">{{ $t('message.membership') }} {{ $t('message.delete') }}</router-link><br />
          <small class="flix-html-small">({{ $t('message.allDataWillBeDeleted') }})</small>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    profileParts () { return import('@/components/profile/parts') },
    premium () { return import('@/components/membership/premium') },
    freemium () { return import('@/components/membership/freemium') }
  },
  props: {},
  data () {
    return {
      membership: false,
      variables: this.$getUserVariables()
    }
  },
  methods: {
    getMembership () {
      this.$flix_post({
        url: 'user/get_membership',
        data: {
          user: this.variables.user.md5_id
        },
        callback: function (ret) { this.membership = ret.data[1] }.bind(this)
      })
    }
  },
  mounted () {
    this.getMembership()
  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
  .inactive
    transform: scale(0.9)
    opacity: 0.5
  .active
    box-shadow: 0px 15px 20px 1px rgba(0,0,0,0.5)
</style>
